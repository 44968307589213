






























import Vue from 'vue';

// Image Source: https://undraw.co/illustrations
export default Vue.extend({
  name: 'Landlord',
  data: () => {
    return {
      reasons: [
        {
          active: true,
          img: '1.svg',
          title: 'Cross Platform',
          reason: 'Anytime, Any place, Any device, same experience.',
          note: 'Requires an active internet connection',
        },
        {
          active: false,
          img: '9.svg',
          title: 'University Verification',
          note: 'Subject to availability in your area',
          reason: 'So that students know, you are the real deal.',
        },
        {
          active: true,
          img: '2.svg',
          title: 'Portfolio Management',
          reason: `A digitised place to track all your properties and we will market them for you.`,
        },
        {
          active: true,
          img: '3.svg',
          title: 'Portfolio Reporting',
          reason: `So you can see how you are doing on our site.`,
        },
        {
          active: true,
          img: '4.svg',
          title: 'Portfolio Site',
          reason: `So that you can share your portfolio easily and do not have to worry about managing your own site.`,
        },
        {
          active: true,
          img: '5.svg',
          title: 'Tenancy Management',
          reason: `A single place to store all your documents, contact information and notes.`,
        },
        {
          active: true,
          img: '6.svg',
          title: 'Tenancy Communication',
          reason: `A single source of history for you to track all your communication.`,
          note: 'Feature in preview',
        },
        {
          active: true,
          img: '7.svg',
          title: 'Enquiry Management',
          reason: `So you can better track them and not fill up your inbox.`,
        },
        {
          active: false,
          img: '10.svg',
          title: 'Inventory Management',
          reason: 'A record for yourself and tenants',
        },
        {
          active: true,
          img: '8.svg',
          title: 'And More...',
          reason: `There is so much more coming.`,
          note: 'More Reporting, Inventory Management, University Verification to name a few!',
        },
      ] as LandlordReason[],
    };
  },
  computed: {
    readyFeatures (): LandlordReason[] {
      return this.reasons.filter((r) => r.active);
    },
  },
  methods: {
    getImageUrl (name: string) {
      return window.location.origin + `/img/app/illustrations/` + name;
    },
  },
});

interface LandlordReason {
  active: boolean;
  img: string;
  title: string;
  subtitle?: string;
  reason: string;
  note?: string;
}
