import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: '/',
    component: () => import('@/blueprint/layouts/Default.vue'),
    children: [
      {
        name: 'Landing Page',
        path: '',
        component: () => import('@/blueprint/pages/generic/Landing.vue'),
      },
      {
        name: 'Search Page',
        path: '/search/:area?',
        component: () => import('@/blueprint/pages/generic/Search.vue'),
      },
      {
        name: 'More Info Page',
        path: '/moreinfo/:id/:city',
        component: () => import('@/blueprint/pages/generic/MoreInfo.vue'),
      },
      {
        name: 'Portfolio Page',
        path: '/portfolio/:id?',
        component: () => import('@/blueprint/pages/generic/Portfolio.vue'),
      },
      {
        name: 'Verify Email Page',
        path: '/verify/:id?',
        component: () => import('@/blueprint/pages/system/VerifyEmail.vue'),
      },
      {
        name: 'Reset Password Page',
        path: '/resetpassword/:id?',
        component: () => import('@/blueprint/pages/system/ResetPassword.vue'),
      },
      {
        name: 'Authorize',
        path: '/authorize',
        component: () => import('@/blueprint/pages/system/Authorize.vue'),
      },
      {
        name: 'Legal',
        path: '/legal/:type',
        component: () => import('@/blueprint/pages/system/Legal.vue'),
      },
    ],
  },
  {
    name: 'Landlord Layout',
    path: '/landlord',
    component: () => import('@/blueprint/layouts/Landlord.vue'),
    redirect: '/landlord/portfolio',
    children: [
      {
        name: 'Reports Page',
        path: 'reports',
        component: () => import('@/blueprint/pages/landlord/Reports.vue'),
      },
      {
        name: 'Portfolio List Page',
        path: 'portfolio',
        component: () => import('@/blueprint/pages/landlord/Portfolio-List.vue'),
      },
      {
        name: 'Portfolio Add Page',
        path: 'portfolio/add',
        component: () => import('@/blueprint/pages/landlord/Portfolio-Upsert.vue'),
      },
      {
        name: 'Portfolio Update Page',
        path: 'portfolio/update',
        component: () => import('@/blueprint/pages/landlord/Portfolio-Upsert.vue'),
      },
      {
        name: 'Tenancies List Page',
        path: 'portfolio/tenancies/:id/:city',
        component: () => import('@/blueprint/pages/landlord/Tenancy-List.vue'),
      },
      {
        name: 'Tenancy Page',
        path: 'portfolio/tenancy/:id/:partition',
        component: () => import('@/blueprint/pages/landlord/Tenancy.vue'),
      },
      {
        name: 'Enquiry Page',
        path: 'enquiry',
        component: () => import('@/blueprint/pages/landlord/Enquiry.vue'),
      },
      {
        name: 'Share Page',
        path: 'share',
        component: () => import('@/blueprint/pages/landlord/Portfolio-Share.vue'),
      },
      {
        name: 'Academy Page',
        path: 'academy',
        component: () => import('@/blueprint/pages/landlord/Academy.vue'),
      },
      {
        name: 'Settings Page',
        path: 'settings',
        component: () => import('@/blueprint/pages/landlord/Settings.vue'),
      },
      {
        name: 'Ideas Page',
        path: 'idea',
        component: () => import('@/blueprint/pages/landlord/Idea.vue'),
      },
    ],
  },
];

// Leave this alone
routes.push({
  path: '*',
  component: () => import('@/blueprint/pages/system/Error404.vue'),
});

export default routes;
