import axios, { AxiosInstance, AxiosError, AxiosResponse } from 'axios';
import { Notify } from 'quasar';
import gql from 'graphql-tag';
import { CombinedVueInstance } from 'vue/types/vue';
import { Auth0Instance } from '@/assets/plugins/auth0Client3';
import { DocumentNode } from 'graphql';

// All possible endpoints
type QueryEndpoints = 'Me' | 'GetUser' | 'GetProperty' | 'SearchProperty' | 'GetEnquiry' | 'GetTenancy' | 'GetAnalyticsGroup1' | '_blank';
type MutationEndpoints = 'CreateUser' | 'LoginUser' | 'CreateProperty' | 'UpdateProperty' | 'CreateEnquiry' | 'UpdateEnquiry' | 'RespondToEnquiry' | 'CreateIdea' | 'UpdateUser' | 'CreateTenancy' | 'UpsertTODO' | 'UpsertTenant' | 'TenancySendSMSMessage' | 'UpsertScheduledMessage' | 'UpdateTenancy' | 'ObsoleteUser' | 'VerifyUserRequest';

export class GQLClient {
  // Class variables
  private client: AxiosInstance;
  private url: string;
  private timeouts: number[] = [];
  private timeoutFacts: string[] = [
    'The unicorn is the national animal of Scotland.',
    'Bees sometimes sting other bees.',
    'The total weight of ants on earth once equaled the total weight of people.',
    'The healthiest place in the world is in Panama.',
    'Dogs actually understand some English.',
    'Humans are just one of the estimated 8.7 million species on Earth.',
  ];
  private instance: CombinedVueInstance<any, any, any, any, any>;

  // Constructor
  constructor (url: string, bearer?: string) {
    this.url = url;
    this.client = axios;
    // Set bearer token if passed
    if (bearer) {
      this.SetBearerToken(bearer);
    }
    // Set timeout - Default set to 30s
    this.client.defaults.timeout = 30000;
  }

  // Methods
  public SetBearerToken (token: string, instance: CombinedVueInstance<any, any, any, any, any> = null) {
    this.client.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    this.instance = instance;
  }

  public ClearBearerToken () {
    delete this.client.defaults.headers.common['Authorization'];
    this.instance = null;
  }

  public async Query<T> (endpoint: QueryEndpoints, query: GQLTagRequestObject, variables: Record<string, any>): Promise<Partial<T>> {
    return await this.Request<T>(endpoint, query.loc!.source.body, variables, true);
  }

  public async Mutation<T> (endpoint: MutationEndpoints, mutation: GQLTagRequestObject, variables: Record<string, any>): Promise<Partial<T>> {
    return await this.Request<T>(endpoint, mutation.loc!.source.body, variables, false);
  }

  public Warm () {
    // Ping API when the app is first loaded to warm up
    const query: GQLTagRequestObject = gql`
      query {
        _blank
      }
    `;
    console.time('Warm');
    this.Query('_blank', query, {}).then((_response: any) => {
      console.timeEnd('Warm');
    }).catch(() => {
      console.warn('Failed to ping API');
    });
  }

  private async Request<T> (endpoint: QueryEndpoints | MutationEndpoints, queryOrMutation: string, variables: Record<string, any>, isQuery: boolean) {
    // Validate request
    if (this.ValidateRequest(endpoint, queryOrMutation, variables, isQuery)) {
      return await this.CallAPI<T>(queryOrMutation, variables, endpoint);
    } else {
      throw new Error('Invalid Request');
    }
  }

  private ValidateRequest (endpoint: QueryEndpoints | MutationEndpoints, query: string, variables: Record<string, any>, isQuery: boolean) {
    let valid: boolean = false;
    // Determine if endpoint exists in string
    if (query.indexOf(endpoint) > -1) {
      // Check if query or mutation keyword exists in string
      if (isQuery) {
        if (query.indexOf('query') === -1) { console.error('Query does not contain keyword query'); }
      } else {
        if (query.indexOf('mutation') === -1) { console.error('Mutation does not contain keyword mutation'); }
      }
      // Check if variables keys length matches regex groups
      // DevNote: Should be x2; one to declare, one to initialise
      const regexGQLVariables: RegExp = new RegExp(/(\$[a-zA-Z0-9]{1,})/gm);
      const regexMatches: RegExpMatchArray | null = query.match(regexGQLVariables);
      if (regexMatches) {
        if (regexMatches.length === (Object.keys(variables).length * 2)) {
          valid = true;
        } else {
          console.error('Variables declared then not initialised or vice versa');
        }
      } else {
        // Me and _blank endpoints are special cases...
        if (endpoint === 'Me' || endpoint === '_blank') {
          valid = true;
        } else {
          console.warn('Query has no variables, you sure about this?');
        }
      }
    } else {
      console.error('Endpoint does not exist in query');
    }
    return valid;
  }

  private async CallAPI<T> (query: string, variables: Record<string, any>, endpoint: QueryEndpoints | MutationEndpoints): Promise<Partial<T>> {
    try {
      // Show popup at 15 secs
      this.CreateTimeoutMessage('Just warming up, almost done!', 15000);
      // Measure performance - Start
      const start: number = performance.now();
      // Send request
      const response: AxiosResponse = await this.client.post<T>(
        this.url,
        {
          query,
          variables,
        }, // Post Data
        {
          timeout: 60000,
        }, // Axios Config
      );
      // Measure performance - End
      const end: number = performance.now();
      // Calc request time TODO: Link up request
      console.log('Request took', (end - start), 'ms');
      // If error - GQL Generic Error
      if (response.data.errors) {
       this.HandleError(response.data);
       throw response.data.errors;
      } else {
        // Return data if no errors found
        return response.data.data[endpoint];
      }
    } catch (e) {
      // Extract error
      const err: AxiosError['response'] = e.response;
      // If error - GQL Generic Error
      if (err && err.data && err.data.errors) {
        this.HandleError(err.data);
        throw err.data.errors;
      } else {
        throw e;
      }
      // Note: Handle errors where ETags do not match here!
    } finally {
      // Clear popup timeouts from appearing for long standing requests
      this.ClearTimeouts();
    }
  }

  private HandleError (response: GQLErrorResponse) {
    if (response.errors) {
      for (const error of response.errors) {
        // Call Quasar Notify API to give feedback
        Notify.create({
          message: `
            <p style="margin:0;margin-top:1rem"><b>Type: </b>${error.extensions.code}</p>
            <p style="margin:0;margin-bottom:1rem"><b>Message: </b>${error.message}</p>
          `,
          html: true,
          color: 'red',
          position: 'top-right',
          timeout: 3000,
          textColor: 'white',
        });
        // Reroute if authenticated
        if (error.message === 'Unauthenticated' || error.message === 'Unauthorized') {
          // Rereoute
          if (this.instance) {
            (this.instance.$auth0 as Auth0Instance).logout(this.instance);
          }
        }
      }
    }
  }

  private CreateTimeoutMessage (message: string, duration: number) {
    // Only push if theres less than 2 actions in the queue (Prevents SPAM)
    if (this.timeouts.length < 2) {
      const timeoutRef: number = window.setTimeout(() => {
        Notify.create({
          message: `
            <p><b>Message: </b>${message}</p>
            <p><b>Fun Fact: </b>${this.PickRandomFact()}</p>
          `,
          html: true,
          color: 'primary',
          position: 'bottom-right',
          timeout: 10000,
          textColor: 'white',
        });
      }, duration);
      // Push into array for clearing only
      this.timeouts.push(timeoutRef);
    }
  }

  private ClearTimeouts () {
    // Clear each timeout such that the popup never opens
    this.timeouts.forEach((timeoutRef) => {
      window.clearTimeout(timeoutRef);
    });
  }

  private PickRandomFact (): string {
    // Extract fact randomly
    const factIdx: number = Math.floor(Math.random() * Math.floor(this.timeoutFacts.length));
    const fact: string = this.timeoutFacts[factIdx];
    // Remove fact from circulation
    this.timeoutFacts.splice(factIdx, 1);
    // Return fact
    return fact;
  }
}

interface GQLErrorResponse {
  errors: GQLErrorObject[];
}

interface GQLErrorObject {
  message: string;
  locations: Array<{ line: number, column: number}>;
  extensions: {
    code: string;
    exception: {
      stacktrace: string[];
    };
  };
}

export interface GQLTagRequestObject extends DocumentNode {
  kind: 'Document';
}
