import { ActionContext, ActionPayload } from '@/interfaces';
import { SearchState } from '@/store/search/state';
import { HandleVuexError } from '@/assets/mixins';
import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { API } from '@lordly/models2/interfaces/gql';

type CurrentActionContext = ActionContext<SearchState>;
type ActionFunction = (context: ActionContext<SearchState>, payload: ActionPayload) => Promise<any>;

const errorHandler: HandleVuexError = new HandleVuexError('Search');

// Fake Loading
export const actionFakeLoading: ActionFunction = async (context: CurrentActionContext) => {
  context.commit('FakeLoading', true);
  setTimeout(() => {
    context.commit('FakeLoading', false);
  }, 250);
};

// Search properties
export const actionSearchProperties: ActionFunction = async (context: CurrentActionContext, payload: ActionPayload) => {
  return new Promise(async (resolve, reject) => {
    // Set loading to be true
    payload.component.$vuex.MutateStore(payload.component, 'search', 'inquiry.loading', true);
    // Create query
    const query: GQLTagRequestObject = gql`
      query ($form: ISearchPropertyForm!, $pagination: ISearchPropertyPagination!, $order: SearchOrder!) {
        SearchProperty (
          input: {
            form: $form,
            pagination: $pagination,
            order: $order
          }
        ) {
          results {
            id
            images {
              url
            }
            address {
              line1
              city
              postcode
              latlng {
                lat
                lng
              }
            }
            details {
              bedroom
              toilet
              type
              ensuite
            }
            dates {
              tenancyStart
              tenancyEnd
              duration {
                monthsRounded
              }
            }
            rent {
              annually
              computed {
                weekly
                monthly
                yearly
              }
            }
            deposit {
              total
              computed {
                perperson
              }
            }
          }
          resultsExist
          hash
          pagination {
            currentPage
            totalPages
            totalRecords
            pageLimit
          }
        }
      }
    `;
    // Generate payload
    const requestPayload: API.SearchPropertyInput = {
      form: {
        area: context.state.form.area,
        bedMin: context.state.form.bedMin,
        bedMax: context.state.form.bedMax,
        depositMin: context.state.form.depositMin,
        depositMax: context.state.form.depositMax,
        duration: context.state.form.duration,
        type: context.state.form.type,
        toiletMin: context.state.form.toiletMin,
        toiletMax: context.state.form.toiletMax,
        ensuite: context.state.form.ensuite,
        rentMin: context.state.form.rentMin,
        rentMax: context.state.form.rentMax,
      },
      pagination: {
        hash: context.state.inquiry.hash || '',
        page: context.state.inquiry.currentPage || 1,
        totalRecords: context.state.inquiry.totalRecords || 0,
      },
      order: context.state.inquiry.order,
    };
    // Send Request
    try {
      const response: Partial<API.SearchPropertyOutput> = await payload.component.$gql.Query<API.SearchPropertyOutput>('SearchProperty', query, requestPayload);
      // Set first search
      payload.component.$vuex.MutateStore(payload.component, 'search', 'inquiry.firstInquiryInvoked', true);
      // Set if results exist
      payload.component.$vuex.MutateStore(payload.component, 'search', 'inquiry.resultsExists', response.resultsExist!);
      // Set results
      payload.component.$vuex.MutateStore(payload.component, 'search', 'inquiry.results', response.results!);
      payload.component.$store.commit('search/GenerateDictionary', 'result');
      // Set current hash
      payload.component.$vuex.MutateStore(payload.component, 'search', 'inquiry.hash', response.hash!);
      // Set current page
      payload.component.$vuex.MutateStore(payload.component, 'search', 'inquiry.currentPage', response.pagination!.currentPage);
      // Set total pages
      payload.component.$vuex.MutateStore(payload.component, 'search', 'inquiry.totalPages', response.pagination!.totalPages);
      // Set total records
      payload.component.$vuex.MutateStore(payload.component, 'search', 'inquiry.totalRecords', response.pagination!.totalRecords);
      resolve();
    } catch (e) {
      console.error(e);
      errorHandler.ReportError(new Error('Failed to search for prorperty'));
      reject();
    } finally {
      payload.component.$vuex.MutateStore(payload.component, 'search', 'inquiry.loading', false);
    }
  });
};
