import { KeyValuePair } from '@/interfaces';
import { Blob } from '@lordly/models2/interfaces';
import { Property, PropertyType, EnsuiteType } from '@lordly/models2/interfaces/models/Property';
import { PropertyReport, GeoRegionBreakdown } from '@lordly/models2/interfaces/models/Analytics';

export default {
  user: {
    id: '',
    subscription: '',
    image: [],
    email: '',
    firstname: '',
    surname: '',
    description: '',
    notify: false,
    contact: {
      email: '',
      mobile: '',
    },
    overrideImage: '',
    createdOn: '',
    lastUpdatedOn: '',
  },
  portfolio: {
    loading: false,
    properties: [],
    display: [],
    currentPage: 1,
    totalPages: 0,
    pageSize: 11,
    filter: null,
    filteredResults: [],
  },
  form: {} as LandlordState['form'],
  reporting: {
    loading: false,
    lastRefresh: '',
    group1: {
      portfolio: [],
      geo: [],
    },
  },
} as LandlordState;

export interface LandlordState {
  user: {
    id: string;
    subscription: string;
    image: Blob[]
    email: string;
    firstname: string;
    surname: string;
    description: string | null;
    notify: boolean;
    contact: {
      email: string;
      mobile: string | null;
    }
    overrideImage: string;
    createdOn: string | Date;
    lastUpdatedOn: string | Date;
  };
  portfolio: {
    loading: boolean;
    properties: IndexedProperty[];
    display: IndexedProperty[];
    currentPage: number;
    totalPages: number;
    pageSize: number;
    filter: string | null;
    filteredResults: IndexedProperty[];
  };
  form: {
    extras: {
      id: string;
      partition: string;
      idx: number;
    },
    address: {
      line1: string;
      line2: string;
      line3: string;
      line4: string;
      city: string;
      county: string;
      postcode: string;
      latlng: {
        lat: number;
        lng: number;
      }
    },
    details: {
      bedroom: number;
      toilet: number;
      ensuite: EnsuiteType
      type: PropertyType;
    },
    dates: {
      tenancyStart: string,
      tenancyEnd: string,
    },
    rent: {
      annually: string;
    },
    deposit: {
      total: string;
      scheme: string;
    },
    rentIncludes: KeyValuePair[],
    billEstimates: KeyValuePair[],
    fees: KeyValuePair[],
    certificates: Certificate[],
    available: boolean;
    description: string;
    images: Blob[];
  };
  reporting: {
    loading: boolean;
    lastRefresh: string;
    group1: {
      portfolio: PropertyReport[];
      geo: GeoRegionBreakdown[]
    }
  };
}

export interface Certificate {
  label: string;
  ref: string;
  expiry: string;
}

export interface IndexedProperty {
  idx: number;
  property: Partial<Property>;
}
