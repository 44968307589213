import { ActionContext, ActionPayload } from '@/interfaces';
import { LandlordState } from '@/store/landlord/state';
import { HandleVuexError } from '@/assets/mixins';
import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { User } from '@lordly/models2/interfaces/models/User';
import { Property } from '@lordly/models2/interfaces/models/Property';
import { API } from '@lordly/models2/interfaces/gql';

type CurrentActionContext = ActionContext<LandlordState>;
type ActionFunction = (context: ActionContext<LandlordState>, payload: ActionPayload) => Promise<any>;

const errorHandler: HandleVuexError = new HandleVuexError('Landlord');

// Get properties for landlords portfolio
/*
* DevNote: The reason this method does not scale well is because the GQL Server has to post process X documents
* which takes many seconds to perform. Possible fix would be to paginate it but then that introduces another set of
* complexities such as adding a search endpoint when searching your portfolio etc.
*/
export const actionGetPortfolio: ActionFunction = async (_context: CurrentActionContext, payload: ActionPayload): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    // Create Query
    const query: GQLTagRequestObject = gql`
      query {
        Me {
          portfolio {
            id
            images {
              id
              url
            }
            address {
              line1
              city
              partition
            }
            available
            meta {
              createdOn
              lastUpdatedOn
            }
            tenancyCount
          }
        }
      }
    `;
    try {
      // Set loading to be true
      payload.component.$store.commit('landlord/MutatePortfolioLoading', true);
      const response: Partial<User> = await payload.component.$gql.Query<Partial<User>>('Me', query, {}) ;
      // Mutate store
      if (response) {
        payload.component.$store.commit('landlord/MutatePortfolioSetProperties', response.portfolio!);
        // payload.component.$vuex.MutateStore(payload.component, 'landlord', 'portfolio.properties', response.portfolio!);
      }
      // Resolve
      resolve();
    } catch (e) {
      errorHandler.ReportError(e);
      reject();
    } finally {
      payload.component.$store.commit('landlord/MutatePortfolioLoading', false);
    }
  });
};

export const actionGetPropertyForUpdate: ActionFunction = async (_context: CurrentActionContext, payload: ActionPayload): Promise<Partial<Property>> => {
  return new Promise(async (resolve, reject) => {
    // Determine if payload is valid
    if (payload && payload.id && payload.partition) {
      // Create query
      const query: GQLTagRequestObject = gql`
        query ($id: String!, $partition: String) {
          GetProperty (
            input: {
              id: $id,
              partition: $partition
            }
          ) {
            id
            images {
              id,
              url
            },
            available
            address {
              line1
              line2
              line3
              line4
              city
              county
              postcode
              latlng {
                lat
                lng
              }
              partition
            }
            details {
              bedroom
              toilet
              ensuite
              type
            }
            dates {
              tenancyStart
              tenancyEnd
            }
            rent {
              annually
            }
            deposit {
              total
              scheme
            }
            rentIncludes {
              label
              value
            }
            billEstimates {
              label
              value
            }
            fees {
              label
              value
            }
            certificates {
              label
              ref
              expiry
            }
            description
          }
        }
      `;
      // Create payload
      const requestPayload: API.GetPropertyInput = {
        id: payload.id,
        partition: payload.partition,
      };
      // Send Request
      try {
        const response: Partial<Property> = await payload.component.$gql.Query<Partial<Property>>('GetProperty', query, requestPayload);
        resolve(response);
      } catch (e) {
        console.error(e);
        reject();
      }
    } else {
      errorHandler.ReportError(new Error('Missing id or partiton'));
      reject();
    }
  });
};

export const actionGetAnalyticsGroup1: ActionFunction = async (context: CurrentActionContext, payload: ActionPayload) => {
  // Create query
  const query: GQLTagRequestObject = gql`
    query ($start: String!, $end: String!) {
      GetAnalyticsGroup1 (
        input: {
          start: $start,
          end: $end
        }
      ) {
        portfolio {
          id
          partition
          views {
            date
            count
          }
          evolutions {
            date
            count
          }
          moreinfos {
            date
            count
          }
          enquiries {
            date
            count
          }
        }
        geographical {
          region
          count
        }
      }
    }
  `;
  // Create payload
  const endDate: string = new Date().toISOString();
  const startDate: string = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString();
  const queryPayload: API.DateRangeInput = {
    end: endDate,
    start: startDate,
  };
  // Send query
  try {
    // Set loading
    payload.component.$vuex.MutateStore(payload.component, 'landlord', 'reporting.loading', true);
    const response: API.GetAnalyticsGroup1Response = await payload.component.$gql.Query('GetAnalyticsGroup1', query, queryPayload) as API.GetAnalyticsGroup1Response;
    // Set in store
    payload.component.$vuex.MutateStore(payload.component, 'landlord', 'reporting.group1.portfolio', response.portfolio);
    payload.component.$vuex.MutateStore(payload.component, 'landlord', 'reporting.group1.geo', response.geographical);
    // Update timestamp
    payload.component.$vuex.MutateStore(payload.component, 'landlord', 'reporting.lastRefresh', new Date().toISOString());
  } catch (e) {
    console.error(e);
  } finally {
    payload.component.$vuex.MutateStore(payload.component, 'landlord', 'reporting.loading', false);
  }
};
