













import Vue from 'vue';

export default Vue.extend({
  name: 'UI-CloseBtn-Component',
  methods: {
    emitClose () {
      this.$emit('close');
    },
  },
});
