import { HandleVuexError, MutateVuexStore, MergeObject, ComputePagination } from '../../assets/mixins';
import { MutationPayload } from '@/interfaces';
import { Property } from '@lordly/models2/interfaces/models/Property';
import { LandlordState, IndexedProperty } from './state';
import { KeyValuePair, Blob } from '@lordly/models2/interfaces';

const errorHandler: HandleVuexError = new HandleVuexError('Landlord');

export function MutateStore (state: LandlordState, payload: MutationPayload) {
  MutateVuexStore(errorHandler, state, payload);
}

export interface MutatePortfolioPayload {
  index: string;
  body: Partial<Property>;
}
export function MutatePortfolioProperty (state: LandlordState, payload: MutatePortfolioPayload) {
  // Determine if index exists
  if (payload.index) {
    const numericIdx: number = parseInt(payload.index);
    // Get property
    const property: Partial<Property> = state.portfolio.properties[numericIdx].property;
    // Update property
    let updatedProperty: Partial<Property> = MergeObject(property, payload.body);
    // Determine if obsoleted
    if (updatedProperty.meta!.obsolete) {
      state.portfolio.properties.splice(numericIdx, 1);
      // Recalculate indexes of properties
      for (const idx in state.portfolio.properties) {
        if (state.portfolio.properties[idx]) {
          state.portfolio.properties[idx].idx = parseInt(idx);
        }
      }
      // Update pagination
      MutatePortfolioRecomputePagination(state);
    }
  } else {
    errorHandler.ReportError(new Error('Property index not provided'));
  }
}

export function MutatePortfolioSetProperties (state: LandlordState, payload: Array<Partial<Property>>) {
  if (payload) {
    if (payload.length !== undefined) {
      // Extract payload
      const portfolio: Array<Partial<Property>> = payload;
      // Update main properties array
      state.portfolio.properties = [];
      for (const idx in portfolio) {
        if (portfolio[idx]) {
          const property: Partial<Property> = portfolio[idx];
          const newIndexedProperty: IndexedProperty = {
            idx: parseInt(idx),
            property,
          };
          state.portfolio.properties.push(newIndexedProperty);
        }
      }
      // Compute pagination
      MutatePortfolioRecomputePagination(state);
    } else {
      errorHandler.ReportError(new Error('MutatePortfolioSetProperties - Payload not type array'));
    }
  } else {
    errorHandler.ReportError(new Error('MutatePortfolioSetProperties - Payload not provided'));
  }
}

export function MutatePortfolioLoading (state: LandlordState, payload: boolean) {
  if (payload !== undefined && (typeof payload === 'boolean')) {
    state.portfolio.loading = payload;
  } else {
    errorHandler.ReportError(new Error(`MutatePortfolioLoading - Payload not type boolean`));
  }
}

export function MutatePortfolioRecomputePagination (state: LandlordState) {
  // Extract portfolio from state
  const portfolio: LandlordState['portfolio'] = state.portfolio;
  let key: string = '';
  // Determine whether to use filtered results or not
  if (portfolio.filter) {
    key = 'filteredResults';
  } else {
    key = 'properties';
  }
  ComputePagination(state.portfolio, key);
}

export function MutatePortfolioSetPage (state: LandlordState, payload: number) {
  if (payload && typeof payload === 'number') {
    state.portfolio.currentPage = payload;
    MutatePortfolioRecomputePagination(state);
  } else {
    errorHandler.ReportError(new Error('MutatePortfolioSetPage - Payload not provided or value is not of type number'));
  }
}

export function MutatePortfolioFilter (state: LandlordState, payload: string) {
  const regexSanitizePayload: RegExp = new RegExp(/[\s]{1,}/gm, '');
  const filterWord: string = payload.toLowerCase().replace(regexSanitizePayload, '');
  // Search portfolio
  state.portfolio.filteredResults = state.portfolio.properties.filter((indexedProperty) => {
    if (indexedProperty.property.address!.line1.replace(regexSanitizePayload, '').toLowerCase().indexOf(filterWord) > -1 || indexedProperty.property.address!.city.replace(regexSanitizePayload, '').toLowerCase().indexOf(filterWord) > -1) {
      return indexedProperty;
    }
  });
  // Set payload exactly to be filter results to prevent UI funky rerendering
  state.portfolio.filter = payload;
  // Update pagination
  MutatePortfolioRecomputePagination(state);
}

export function MutateForm (state: LandlordState, payload: Partial<Property> & { extras: { id: string, partition: string, idx: number}}) {
  const property: LandlordState['form'] = state.form;
  // Update extras
  if (payload.extras) {
    property.extras.id = payload.extras.id ? payload.extras.id : property.extras.id;
    property.extras.partition = payload.extras.partition ? payload.extras.partition : property.extras.partition;
    property.extras.idx = (payload.extras.idx > -1) ? payload.extras.idx : property.extras.idx;
  }
  // Begin updating each segment indivudally
  if (payload.id) {
    property.extras.id = payload.id;
  }
  // Update address
  if (payload.address) {
    property.address.line1 = payload.address.line1 ? payload.address.line1 : property.address.line1;
    property.address.line2 = payload.address.line2 ? payload.address.line2 : property.address.line2;
    property.address.line3 = payload.address.line3 ? payload.address.line3 : property.address.line3;
    property.address.line4 = payload.address.line4 ? payload.address.line4 : property.address.line4;
    property.address.city = payload.address.city ? payload.address.city : property.address.city;
    property.address.county = payload.address.county ? payload.address.county : property.address.county;
    property.address.postcode = payload.address.postcode ? payload.address.postcode : property.address.postcode;
    // Update latlng
    if (payload.address.latlng) {
      property.address.latlng.lat = payload.address.latlng.lat ? payload.address.latlng.lat : property.address.latlng.lat;
      property.address.latlng.lng = payload.address.latlng.lng ? payload.address.latlng.lng : property.address.latlng.lng;
    }
    // Set partition
    if (payload.address.partition) {
      property.extras.partition = payload.address.partition;
    }
  }
  // Update details
  if (payload.details) {
    property.details.bedroom = payload.details.bedroom ? payload.details.bedroom : property.details.bedroom;
    property.details.toilet = payload.details.toilet ? payload.details.toilet : property.details.toilet;
    property.details.type = payload.details.type ? payload.details.type : property.details.type;
    property.details.ensuite = payload.details.ensuite ? payload.details.ensuite : property.details.ensuite;
  }
  // Update dates
  if (payload.dates) {
    property.dates.tenancyStart = payload.dates.tenancyStart ? (payload.dates.tenancyStart as string) : property.dates.tenancyStart;
    property.dates.tenancyEnd = payload.dates.tenancyEnd ? (payload.dates.tenancyEnd as string) : property.dates.tenancyEnd;
  }
  // Update rent
  if (payload.rent) {
    property.rent.annually = payload.rent.annually ? ((parseFloat(payload.rent.annually) * 1) / 1).toFixed(2) : property.rent.annually;
  }
  // Update deposit
  if (payload.deposit) {
    property.deposit.total = payload.deposit.total ? ((parseFloat(payload.deposit.total) * 1) / 1).toFixed(2) : property.deposit.total;
    property.deposit.scheme = payload.deposit.scheme ? payload.deposit.scheme : property.deposit.scheme;
  }
  // Update rentIncludes
  if (payload.rentIncludes && payload.rentIncludes.length > 0) {
    property.rentIncludes = payload.rentIncludes;
  }
  // Update estimatedBills
  if (payload.billEstimates && payload.billEstimates.length > 0) {
    property.billEstimates = [];
    for (const idx in payload.billEstimates) {
      if (payload.billEstimates[idx]) {
        const keyValuePair: KeyValuePair = payload.billEstimates[idx];
        keyValuePair.value = (((parseFloat(keyValuePair.value) * 1) / 1).toFixed(2)) !== 'NaN' ? (((parseFloat(keyValuePair.value) * 1) / 1).toFixed(2)) : '';
        property.billEstimates.push(keyValuePair);
      }
    }
  }
  // Update fees
  if (payload.fees) {
    property.fees = [];
    for (const idx in payload.fees) {
      if (payload.fees[idx]) {
        const keyValuePair: KeyValuePair = payload.fees[idx];
        keyValuePair.value = (((parseFloat(keyValuePair.value) * 1) / 1).toFixed(2)) !== 'NaN' ? (((parseFloat(keyValuePair.value) * 1) / 1).toFixed(2)) : '';
        property.fees.push(keyValuePair);
      }
    }
  }
  // Update certificates
  if (payload.certificates && payload.certificates.length > 0) {
    property.certificates = payload.certificates;
  }
  // Update images
  if (payload.images) {
    property.images = [];
    for (const idx in payload.images) {
      if (payload.images[idx]) {
        const image: Blob = payload.images[idx];
        if (image.id && image.url) {
          const propertyImage: Blob = {
            id: image.id,
            url: image.url,
          };
          property.images.push(propertyImage);
        } else {
          console.error('Vuex - Landlord Module - mTemplateProperty Mutation - Property image object provided in incorrect format.');
        }
      }
    }
  }
  // Update description
  property.description = payload.description ? payload.description : property.description;
  // Update available
  if (payload.available === true || payload.available === false) {
    property.available = payload.available;
  }
}

export function MutateResetForm (state: LandlordState) {
  state.form = {
    extras: {
      id: '',
      partition: '',
      idx: -1,
    },
    images: [],
    available: false,
    address: {
      line1: '',
      line2: '',
      line3: '',
      line4: '',
      city: '',
      county: '',
      postcode: '',
      latlng: {
        lat: -1,
        lng: -1,
      },
    },
    details: {
      bedroom: 1,
      toilet: 1,
      ensuite: 'No',
      type: 'House',
    },
    dates: {
      tenancyStart: '',
      tenancyEnd: '',
    },
    rent: {
      annually: '',
    },
    deposit: {
      total: '',
      scheme: 'Other Deposit Scheme',
    },
    rentIncludes: [
      {
        label: 'Gas/Electricity',
        value: false,
      },
      {
        label: 'Water/Sewage',
        value: false,
      },
      {
        label: 'TV Licence',
        value: false,
      },
      {
        label: 'TV Subscription',
        value: false,
      },
    ],
    billEstimates: [
      {
        label: 'Gas/Electricity',
        value: '',
      },
      {
        label: 'Water/Sewage',
        value: '',
      },
    ],
    fees: [],
    certificates: [
      {
        label: 'HMO Licence',
        ref: '',
        expiry: '',
      },
      {
        label: 'Electrical Safety Certificate',
        ref: '',
        expiry: '',
      },
      {
        label: 'Gas Safety Certificate',
        ref: '',
        expiry: '',
      },
      {
        label: 'EPC Certificate',
        ref: '',
        expiry: '',
      },
    ],
    description: '',
  };
}
