import { CombinedVueInstance } from 'vue/types/vue';
import { JSTypes, MutationPayload, VueInputComponent } from '@/interfaces';
import axios from 'axios';
import { date } from 'quasar';

export function ClickAway (component: CombinedVueInstance<any, any, any, any, any>, refName: string, componentFunctionToToggle: string, clear?: boolean) {
  // Create function
  const clickFunction: (e: Event) => void = (e) => {
    e.preventDefault();
    let targetElement: HTMLInputElement | null = e.target as HTMLInputElement;
    // Check for click inside
    do {
      if (targetElement === component.$refs[refName]) {
        return;
      }
      // Move up the DOM
      targetElement = targetElement ? targetElement.parentNode as HTMLInputElement : null;
    } while (targetElement);
    // If click is outside
    component[componentFunctionToToggle]();
  };
  // Determine wheather to add or remove
  if (clear) {
    document.removeEventListener('mouseup', clickFunction);
  } else {
    document.addEventListener('mouseup', clickFunction, { once: true });
  }
}

export function UpdateApp () {
  if (navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      // Clear installed service workers
      for (let registration of registrations) {
        registration.unregister();
      }
      // Reload current window
      location.reload();
    });
  }
}

export function ValidateFields (component: CombinedVueInstance<any, any, any, any, any>, inputs: string[], inputInChildComponent: boolean = false) {
  let valid: boolean = true;
  // Perform action per input
  for (const idx in inputs) {
    if (inputs[idx] && component.$refs[inputs[idx]]) {
      // Get component
      const targetComponent: VueInputComponent = component.$refs[inputs[idx]] as VueInputComponent;
      // Determine if valid function exists
      if (typeof targetComponent.validate === 'function') {
        // Validate and determine if field has error
        targetComponent.validate();
        if (targetComponent.hasError && valid) {
          valid = false;
        }
      } else {
        // Determine if ref is of array type (To accomidate dynamic refs used in for-loops)
        if (typeof (targetComponent as unknown as any[]).length === 'number') {
          // Grab the first component
          if ((targetComponent as unknown as any[]).length > 0) {
            const firstComponent: VueInputComponent = (targetComponent as unknown as any[])[0];
            if (typeof firstComponent.validate === 'function') {
              firstComponent.validate();
              if (firstComponent.hasError && valid) {
                valid = false;
              }
            } else {
              // Accomidate handling QDatePickers wrapped in LDatePicker when done in for-loops with refs
              if (inputInChildComponent) {
                valid = ValidateChildFields(firstComponent, valid);
              }
            }
          }
        }
      }
      // Accodmiate handling QDatePickers wrapped in LDatePicker
      if (inputInChildComponent) {
        valid = ValidateChildFields(targetComponent, valid);
      }
    }
  }
  return valid;
}

function ValidateChildFields (component: CombinedVueInstance<any, any, any, any, any>, validity: boolean) {
  for (const child in component.$children) {
    if (component.$children[child]) {
      const childComponent: VueInputComponent  = component.$children[child] as VueInputComponent;
      // Determine if child component is input
      if (typeof childComponent.validate === 'function') {
        childComponent.validate();
        if (validity && childComponent.hasError) {
          validity = false;
        }
      }
    }
  }
  return validity;
}

export function GetCachedData (file: string) {
  return axios({
    method: 'GET',
    url: window.location.origin + '/cache/' + file,
    timeout: 60000,
  });
}

export function Captialize (input: string) {
  if (input && typeof input === 'string') {
    return input.charAt(0).toUpperCase() + input.slice(1);
  } else {
    return null;
  }
}

export function MutateVuexStore (handler: HandleVuexError, state: Record<string, any>, payload: MutationPayload): void {
  // Determine if we have payload
  if (payload.key !== undefined && payload.value !== undefined) {
    // Get type
    const type: JSTypes = typeof payload.value;
    const keys: string[] = payload.key.split('.');
    const key: string | undefined = keys.shift();
    const nestedKey: boolean = keys.length > 0;
    // Determine if key is valid
    if (key) {
      // Determine action
      if (nestedKey) {
        // Create new key & update payload
        const newKey: string = keys.join('.');
        payload.key = newKey;
        // Recursively call component
        MutateVuexStore(handler, state[key], payload);
        return;
      } else {
        // Determine if attribute exists
        if (state[key] !== undefined) {
          // Determine course of action based on value type...
          switch (type) {
            case 'string':
            case 'bigint':
            case 'number':
            case 'boolean':
              // Type check (MADNESS I KNOW)
              if (typeof state[key] === typeof payload.value) {
                state[key] = Sanitize(payload.value);
              } else {
                console.log(state[key], payload.value);
                handler.ReportError(new Error(`MutateStore - Expected type ${typeof state[key]} but got type ${typeof payload.value} for attribute ${key}`));
              }
              break;
            case 'object':
              // Determine if array
              const stateLength: number | undefined | null = state[key] ? (state[key].length) : null;
              if (stateLength !== undefined || stateLength !== null) {
                // Expect payload to be array
                const payloadLength: number | undefined | null = payload.value ? ((payload.value as any[]).length)  : null;
                if (payloadLength !== undefined || payloadLength !== null) {
                  state[key] = payload.value;
                } else {
                  handler.ReportError(new Error(`MutateStore - Expected type array but got type ${typeof payload.value} without property length for attribute ${key}`));
                }
              }
              break;
            default:
              break;
          }
        } else {
          handler.ReportError(new Error('MutateStore - Cannot find attribute with name ' + key));
        }
      }
    }
  } else {
    console.error(payload);
    handler.ReportError(new Error('MutateSore - Incorrect payload provided'));
  }
}

export function SanitizeObject (object: Record<string, any>) {
  // Extract keys
  const keys: string[] = Object.keys(object);
  // Recursively check key to determine if we need to santize or not
  for (const key of keys) {
    switch (typeof object[key]) {
      case 'string':
        object[key] = Sanitize(object[key]);
        break;
      case 'object':
        const length: number | undefined | null = object[key] ? (object[key].length) : null;
        // If an real object
        if (length === undefined) {
          object[key] = SanitizeObject(object[key]);
        } else {
          // If array of objects or strings (Arrays in js are classed as objects with the length attribute)
          if (length && length > 0) {
            // Determine if array of objects or strings
            const firstElement: any = object[key][0];
            const typeOfFirstElement: JSTypes = typeof firstElement;
            if (typeOfFirstElement === 'string' || typeOfFirstElement === 'object') {
              for (let i: number = 0; i < length; i++) {
                const element: string | object = object[key][i];
                if (typeof element === 'string') {
                  object[key][i] = Sanitize(element);
                } else {
                  object[key][i] = SanitizeObject(element);
                }
              }
            }
          }
        }
        break;
      default:
        break;
    }
  }
  return object;
}

export function Sanitize (input: any, hard: boolean = false): any {
  // Determine type
  const type: JSTypes = typeof input;
  // Determine sanitization process
  switch (type) {
    case 'string':
      if (hard) {
        return input
                .replace(/\s+/gm, '') // Replace spaces
                .replace(/(<|\?|>|;|-{2,}|\'|\\'|:|#|$|&{2,}|!{2,})/gm, ''); // Replace dangerous characters HARD
      } else {
        return input
                .replace(/(<|\?|>|;|-{2,}|\'|\\')/gm, ''); // Replace dangerous characters LIGHT
      }
    case 'number':
    case 'bigint':
      const maxInt: number = 2147483640;
      const minInt: number = -2147483639;
      if (input >= minInt && input <= maxInt) {
        return input;
      } else {
        return input >= 0 ? maxInt : minInt;
      }
    case 'boolean':
    case 'object':
      return input;
    default:
      return undefined;
  }
}

export function MergeObject<T> (oldObject: T, newObject: Record<string, any> & Partial<T>) {
  // Recursively update object
  const newObjectKeys: string[] = Object.keys(newObject);
  for (const idx in newObjectKeys) {
    if (newObjectKeys[idx]) {
      const key: string = newObjectKeys[idx];
      // Determine if key exists in old object
      if (oldObject[key as keyof T]) {
        // Extract current context of object
        let contextObject: T[keyof T] = oldObject[key as keyof T];
        // Determine if object or not
        switch (typeof contextObject) {
          case 'object':
            // Determine if real object or array
            const length: number | undefined | null = contextObject ? (contextObject as unknown as any[]).length : null;
            // If it is a real object
            if (length === undefined) {
              // Begin recursive update
              contextObject = MergeObject(contextObject, newObject[key]);
            } else {
              // Then it is an array of objects, replace as is
              contextObject = newObject[key];
            }
            break;
          case 'string':
          case 'boolean':
          case 'number':
          case 'bigint':
            contextObject = newObject[key];
            break;
          default:
            break;
        }
        // Set object back
        oldObject[key as keyof T] = contextObject;
      } else {
        // If not does not exist, add it and set the value
        oldObject[key as keyof T] = newObject[key];
        // If object, then run through again recursively to ensure all sub-keys get handled correctly
        if (typeof newObject[key] === 'object') {
          oldObject[key as keyof T] = MergeObject(oldObject[key as keyof T], newObject[key]);
        }
      }
    }
  }
  // Return oldObject as it will have been updated
  return oldObject;
}

export class HandleVuexError {
  private module: string = '';

  constructor (vuexModule: string) {
    this.module = vuexModule;
  }

  public ReportError (e: Error) {
    e.message = 'Vuex - ' + this.module + ' Module - ' + e.message;
    console.error(e);
  }
}

export function DurationMonths (startDate: Date, endDate: Date) {
  // Extract info
  const tenancyStart: Date = startDate;
  const tenancyEnd: Date = endDate;
  // Get months diff
  let monthsDifference: number = date.getDateDiff(tenancyEnd, tenancyStart, 'months') as unknown as number;
  // Add monthDifference to tenancy start to get a precise tenancy end based on calculations
  const tenancyEndBasedOnMonthDiff: Date = date.addToDate(tenancyStart, { month: monthsDifference });
  // Calculate the difference between precise tenancy end and actual tenancy end to get days
  const dayDifference: number = date.getDateDiff(tenancyEnd, tenancyEndBasedOnMonthDiff, 'days') as unknown as number;
  // If day difference greater than 0 round up
  if (dayDifference > 0) {
    monthsDifference++;
  }
  // If monthDifference is not a number
  if (isNaN(monthsDifference)) {
    monthsDifference = 0;
  }
  return monthsDifference;
}

export function GenerateCypressName (templateString: string): string {
  return Sanitize(templateString.toLowerCase(), true).replace(/[\/]/gm, '-');
}

export function CopyToClipBoard (copyValue: string): boolean {
  const textArea: HTMLTextAreaElement = document.createElement('textarea');
  //
  // *** This styling is an extra step which is likely not required. ***
  //
  // Why is it here? To ensure:
  // 1. the element is able to have focus and selection.
  // 2. if element was to flash render it has minimal visual impact.
  // 3. less flakyness with selection and copying which **might** occur if
  //    the textarea element is not visible.
  //
  // The likelihood is the element won't even render, not even a flash,
  // so some of these are just precautions. However in IE the element
  // is visible whilst the popup box asking the user for permission for
  // the web page to copy to the clipboard.
  //

  // Place in top-left corner of screen regardless of scroll position.
  textArea.style.position = 'fixed';
  textArea.style.top = '0';
  textArea.style.left = '0';

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = '2em';
  textArea.style.height = '2em';

  // We don't need padding, reducing the size if it does flash render.
  textArea.style.padding = '0';

  // Clean up any borders.
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';

  // Avoid flash of white box if rendered for any reason.
  textArea.style.background = 'transparent';

  textArea.value = copyValue;
  document.body.appendChild(textArea);
  textArea.select();

  // Determone copy status
  let copyStatus: boolean = false;
  try {
    copyStatus = document.execCommand('copy');
  } catch (e) {
    console.error(e);
    copyStatus = false;
  } finally {
    document.body.removeChild(textArea);
  }
  return copyStatus;
}

interface PaginationObject {
  display: any[];
  currentPage: number;
  totalPages: number;
  pageSize: number;
  [k: string]: any;
}
export function ComputePagination (po: PaginationObject, totalAttributeKey: string) {
  // Extract list with all results
  const listOfAnyObjects: any[] = po[totalAttributeKey] as any[];
  // Determine total pages
  po.totalPages = Math.ceil(listOfAnyObjects.length / po.pageSize);
  // Calculate page offset
  const offset: number = (po.currentPage - 1) * po.pageSize;
  // Set display
  po.display = listOfAnyObjects.slice(offset, (offset + po.pageSize));
}

export function ResetForm (component: CombinedVueInstance<any, any, any, any, any>, refs: string[]) {
  // For each ref, reset
  for (const idx in refs) {
    if (refs[idx]) {
      const key: string = refs[idx];
      if (component.$refs[key]) {
        (component.$refs[key] as VueInputComponent).resetValidation();
      }
    }
  }
}

export function QSelectFilterFn (
    val: string,
    update: (func: () => void) => void,
    abort: () => void,
    component: CombinedVueInstance<any, any, any, any, any>,
    selection: string[],
    filteredArrayAttributeName: string,
    directAttributeName: string = '',
  ) {
  if (val.length < 2) {
    abort();
    return;
  }
  update(() => {
    QSelectFilterUpdateFn(val, component, selection, filteredArrayAttributeName, directAttributeName);
  });
}

export function QSelectFilterUpdateFn (
  val: string,
  component: CombinedVueInstance<any, any, any, any, any>,
  selection: string[],
  filteredArrayAttributeName: string,
  directAttributeName: string = '',
) {
  const needle: string = val.toLowerCase().replace(' ', '');
  component[filteredArrayAttributeName] = selection.filter((s: string) => {
    const sanitized: string = s.toLowerCase().replace(' ', '');
    if (sanitized.indexOf(needle) > -1) {
      if (directAttributeName) {
        if (sanitized === needle) {
          component[directAttributeName] = s;
        }
      }
      return s;
    }
  });
}

// Days of week ordered according to javascript getDay() method 0=Sun,...,6=Sat
const shortDaysOfWeekString: string [] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
const daysOfWeekString: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export function GetDayOfWeek (date: string, short: boolean = false) {
  // Convert to date object
  const datedObjected: any = new Date(date);
  // If date is valid
  if (JSON.stringify(datedObjected) !== 'null') {
    // Get day of week
    const dayOfWeek: number = (datedObjected as Date).getDay();
    // Return long or short hand version, default to long
    if (short) {
      return shortDaysOfWeekString[dayOfWeek];
    } else {
      return daysOfWeekString[dayOfWeek];
    }
  } else {
    throw new Error('Invalid Date: ' + date);
  }
}
