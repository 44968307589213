











import Vue from 'vue';
import {} from 'grecaptcha';

export default Vue.extend({
  name: 'ReCaptcha-Component',
  data: () => {
    return {
      privateRecaptchaToken: '',
    };
  },
  computed: {
    recaptchaToken: {
      get (): string {
        return this.privateRecaptchaToken;
      },
      set (value: string) {
        this.privateRecaptchaToken = value;
        this.$emit('token', this.privateRecaptchaToken);
      },
    },
  },
  mounted () {
    // Render the recaptcha once the component is loaded
    this.$nextTick(() => {
      // Create callback
      const verifyCaptcha: (value: string) => void = (response: string) => {
        this.$nextTick(() => {
          this.recaptchaToken = response;
        });
      };
      // Render
      try {
        grecaptcha.render('recaptcha-main', { callback: verifyCaptcha });
      } catch (e) {
        console.error(e);
        // // If initialisation failed, try again after 2.5 secs
        // setTimeout(() => {
        //   grecaptcha.render('recaptcha-main', { callback: verifyCaptcha });
        // }, 2500);
      }
    });
  },
});
