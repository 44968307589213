import { Property, PropertyType, EnsuiteType } from '@lordly/models2/interfaces/models/Property';
import { SearchOrder } from '@lordly/models2/interfaces/gql';

export default {
  form: {
    area: 'Portsmouth',
    bedMin: 1,
    bedMax: 10,
    rentMin: 10,
    rentMax: 200,
    depositMin: 0,
    depositMax: 1000,
    duration: 24,
    type: '%',
    toiletMin: 1,
    toiletMax: 10,
    ensuite: '%',
    showAdvanced: false,
  },
  inquiry: {
    results: [],
    resultsDictionary: {},
    resultsExists: false,
    resultsSaved: [],
    resultsSavedDictionary: {},
    loading: false,
    firstInquiryInvoked: false,
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    hash: '',
    order: 'Default',
  },
};

export type SearchType = PropertyType | '%';
export type SearchEnsuite = EnsuiteType | '%';
export interface SearchState {
  form: {
    area: string;
    bedMin: number;
    bedMax: number;
    rentMin: number;
    rentMax: number;
    depositMin: number;
    depositMax: number;
    duration: number;
    type: SearchType;
    toiletMin: number;
    toiletMax: number;
    ensuite: SearchEnsuite;
    showAdvanced: boolean;
  };
  inquiry: {
    results: Array<Partial<Property>>;
    resultsDictionary: Record<string, number>;
    resultsExists: boolean;
    resultsSaved: Array<Partial<Property>>;
    resultsSavedDictionary: Record<string, number>;
    loading: boolean;
    firstInquiryInvoked: boolean;
    currentPage: number;
    totalPages: number;
    totalRecords: number;
    hash: string;
    order: SearchOrder;
  };
}
