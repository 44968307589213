import { MutateableTypes, MutationPayload, VueInstance } from '@/interfaces';

type PossibleVuexModules = 'landlord' | 'search' | null;
export class VuexClient {
  // Class variables
  // Constructor
  // Method
  public MutateStore (vueContext: VueInstance, vuexModule: PossibleVuexModules, key: string, value: MutateableTypes) {
    // Set commit type
    let commitType: string = 'MutateStore';
    // Consider Vuex modules
    if (vuexModule) {
      commitType = vuexModule + '/' + commitType;
    }
    // Create payload
    const payload: MutationPayload = {
      key,
      value,
    };
    // Perform commit
    vueContext.$store.commit(commitType, payload);
  }
}
