import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages (): LocaleMessages {
  const locales: any = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach((key: string) => {
    const matched: RegExpMatchArray | null = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale: string = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

// Define Dateforms
const dateTimeFormats: VueI18n.DateTimeFormats = {
  'en-gb': {
    short: { day: '2-digit', month: '2-digit', year: 'numeric' },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric',
    },
  },
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en-gb',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-gb',
  messages: loadLocaleMessages(),
  dateTimeFormats: dateTimeFormats || null,
  silentFallbackWarn: false,
  silentTranslationWarn: false,
});
