import Vue from 'vue';

import './styles/quasar.styl';
import 'quasar/dist/quasar.ie.polyfills';
import iconSet from 'quasar/icon-set/mdi-v3.js';
import '@quasar/extras/mdi-v3/mdi-v3.css';
import {
  Quasar,
  QBtn,
  QIcon,
  QInput,
  QItem,
  QItemSection,
  QItemLabel,
  QCheckbox,
  QSelect,
  QSpinner,
  QAvatar,
  QPopupProxy,
  QDate,
  QChip,
  QTooltip,
  QPagination,
  QDialog,
  ClosePopup,
  QCarousel,
  QCarouselSlide,
  QTab,
  QTabs,
  QEditor,
  QTimeline,
  QTimelineEntry,
  QTable,
  QTh,
  QTr,
  QTd,
  Notify
} from 'quasar';

Vue.use(Quasar, {
  config: {},
  components: {
    QBtn,
    QIcon,
    QInput,
    QItem,
    QItemSection,
    QItemLabel,
    QCheckbox,
    QSelect,
    QSpinner,
    QAvatar,
    QDate,
    QChip,
    QTooltip,
    QPopupProxy,
    QPagination,
    QCarousel,
    QCarouselSlide,
    QTab,
    QTabs,
    QEditor,
    QDialog,
    QTimeline,
    QTimelineEntry,
    QTable,
    QTh,
    QTr,
    QTd,
  },
  directives: {
    ClosePopup
  },
  plugins: [ Notify ],
  iconSet,
 });
