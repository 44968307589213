























































import Vue from 'vue';
import { ClickAway, GetCachedData } from '@/assets/mixins';
// UI
import QCloseBtn from '@/blueprint/components/ui/CloseButton.vue';
// Sidebar Components
import Landlord from '@/blueprint/components/sidebar/Landlord.vue';
import SignUp from '@/blueprint/components/sidebar/SignUp.vue';
import Help from '@/blueprint/components/sidebar/Help.vue';
import VerifyEmailRequest from '@/blueprint/components/sidebar/VerifyEmailRequest.vue';
import { AxiosResponse, AxiosError } from 'axios';

// Illustrations: https://undraw.co/illustrations
export default Vue.extend({
  name: 'Lordly-App',
  components: { Landlord, SignUp, Help, VerifyEmailRequest, QCloseBtn },
  data () {
    return {
      showMobileMenu: false,
      showSidebarMenu: false,
      sidebarComponent: '',
    };
  },
  methods: {
    toggleMobileMenu () {
      // If in mobile viewport, open menu else ignore action
      if (window.innerWidth < 768) {
        this.showMobileMenu = !this.showMobileMenu;
      }
    },
    showSidebar (component: string) {
      // Auth0 Override
      if (component === 'SignIn') {
        this.$auth0.loginWithRedirect();
      } else {
        this.showSidebarMenu = true;
        this.sidebarComponent = component;
        this.showMobileMenu = false;
        // Setup click away event listener
        ClickAway(this, 'appSidebar', 'hideSideBar');
      }
    },
    showSearch () {
      this.hideSideBar();
      if (this.$route.path !== '/') {
        this.$router.push('/');
      }
    },
    hideSideBar () {
      this.showSidebarMenu = false;
      this.sidebarComponent = '';
      if (this.showMobileMenu) {
        this.showMobileMenu = false;
      }
    },
    async loadPossibleCities () {
      try {
        const response: AxiosResponse = await GetCachedData('city.json');
        if (response.status === 200) {
          this.$store.commit('mPossibleCities', response.data);
        }
      } catch (e) {
        console.error(e);
      }
    },
    sidebarAction (payload: SidebarAction) {
      if (payload.state === 'Open') {
        this.showSidebar(payload.component);
      } else {
        console.error('Closed state not implemented');
      }
    },
    acceptedCookiePolicy (): boolean {
      const accepted: string | null =  window.localStorage.getItem('gdpr');
      if (accepted) {
        return accepted === 'true';
      }
      return false;
    },
    invokeCookiePolicy () {
      if (!this.acceptedCookiePolicy()) {
        // Notify cookie banner
        this.$q.notify({
          html: true,
          multiLine: true,
          position: 'bottom-right',
          message: '<p>Our third-party tools use cookies, which are necessary for their functioning and required to achieve the purposes illustrated in the cookie policy.</p>',
          classes: 'cookie-banner',
          color: 'secondary',
          textColor: 'white',
          actions: [
            {
              label: 'Accept',
              color: 'white',
              handler: () => {
                this.$gtag.optIn();
                window.localStorage.setItem('gdpr', 'true');
              },
            },
            {
              label: 'Learn more',
              color: 'white',
              handler: () => {
                this.$router.replace('/legal/cp');
              },
            },
          ],
          timeout: 120000, // 2 mins timeout
        });
      } else {
        this.$gtag.optIn();
      }
    },
  },
  created () {
    // Load Cities
    this.loadPossibleCities();
    // Set locale
    this.$i18n.locale = 'en-gb';
    // Ping API when the app is first loaded to warm up
    this.$gql.Warm();
    // Check GDPR
    this.invokeCookiePolicy();
  },
});

export type RootComponent = Vue & GARootMethods;

export interface GARootMethods {
  sidebarAction: (payload: SidebarAction) => void;
}

export interface SidebarAction {
  state: 'Open' | 'Closed';
  component: 'Landlord' | 'SignUp' | 'Help' | 'VerifyEmailRequest';
}
