





















































































































































































import Vue from 'vue';
import { sameAs, email } from 'vuelidate/lib/validators';
import { ValidateFields } from '@/assets/mixins';
import { API } from '@lordly/models2/interfaces/gql';
import { NotifyAPIOptions } from '@/interfaces/quasar';
import Captcha from '@/blueprint/components/shared/ReCaptcha.vue';

import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';

export default Vue.extend({
  name: 'SignUp',
  components: {
    captcha: Captcha,
  },
  data () {
    return {
      form: {
        firstname: '',
        surname: '',
        email: '',
        password: '',
        passwordConfirmation: '',
      },
      reCaptchaToken: '',
      agreedToTermsAndConditions: false,
      submitButtonLabel: 'Next',
      showPhase2: false,
      creatingAccount: false,
    };
  },
  validations: {
    form: {
      email: {
        email,
      },
      password: {
        goodPassword: (password: string) => {
          return password.length >= 8 &&
          /[a-z]/.test(password) &&
          /[A-Z]/.test(password) &&
          /[0-9]/.test(password);
        },
      },
      passwordConfirmation: {
        sameAsPassword: sameAs('password'),
      },
    },
  },
  computed: {
    signupIllustration (): string {
      return window.location.origin + '/img/app/illustrations/signup.svg';
    },
  },
  methods: {
    validateFields (inputs: string[]) {
      return ValidateFields(this, inputs);
    },
    isPhase1Valid () {
      let hasError: boolean = !this.validateFields(['firstname', 'surname', 'email']);
      // Check if vuelidate plugin has error
      if (!hasError) {
        hasError = (this.$v.form && this.$v.form.email && this.$v.form.email.$error) as boolean;
      }
      return !hasError;
    },
    isPhase2Valid () {
      let hasError: boolean = !this.validateFields(['password', 'passwordConfirmation']);
      // Check if vuelidate plugin has error
      if (!hasError) {
        hasError = (this.$v.form && ((this.$v.form.password && this.$v.form.password.hasError) || (this.$v.form.passwordConfirmation && this.$v.form.passwordConfirmation.hasError))) as boolean;
      }
      return !hasError;
    },
    invokePhase1 () {
      this.showPhase2 = false;
      this.submitButtonLabel = 'Next';
    },
    invokePhase2 () {
      if (this.isPhase1Valid()) {
        this.showPhase2 = true;
        this.submitButtonLabel = 'Validate';
      }
    },
    submitButtonAction () {
      if (this.showPhase2) {
        if (this.submitButtonLabel === 'Click again to sign up!') {
          // Validate the entire form again for banter...
          this.$v.$touch();
          if (!this.isPhase1Valid()) {
            this.invokePhase1();
          } else {
            if (!this.isPhase2Valid()) {
              this.invokePhase2();
            } else {
              if (!this.$v.$error) {
                this.invokeSignUp();
              } else {
                console.warn('Vuelidate in errored state but phase 1 and 2 are valid');
              }
            }
          }
        } else {
          if (this.isPhase2Valid()) {
            this.submitButtonLabel = 'Click again to sign up!';
          }
        }
      } else {
        this.invokePhase2();
      }
    },
    async invokeSignUp () {
      if (this.agreedToTermsAndConditions) {
        if (this.reCaptchaToken) {
          // Set loading to true
          this.creatingAccount = true;
          // Set timeout for slow request update
          const clearTimeout: number = window.setTimeout(() => {
              this.$q.notify({
              position: 'bottom-right',
              message: 'We are just checking a few things, not long now!',
              timeout: 3000,
              color: 'orange',
              textColor: 'white',
              icon: 'mdi-cogs',
            });
          }, 5000);
          // Set timeout for super slow request update
          const clearTimeout2: number = window.setTimeout(() => {
            this.$q.notify({
              position: 'bottom-right',
              message: 'Sorry, just fending off a PIRATE ASSAULT! Almost done!',
              timeout: 5000,
              color: 'black',
              textColor: 'white',
              icon: 'mdi-pirate',
            });
          }, 15000);
          // Create query
          const query: GQLTagRequestObject = gql`
            mutation ($firstname: String!, $surname: String!, $email: String!, $password: String!, $token: String!) {
              CreateUser (
                input: {
                  firstname: $firstname
                  surname: $surname,
                  email: $email,
                  password: $password,
                  token: $token
                }
              ) {
                email
                created
                exists
                errored
              }
            }
          `;
          // Create payload
          const payload: API.CreateUserInput = {
            firstname: this.form.firstname,
            surname: this.form.surname,
            email: this.form.email,
            password: this.form.password,
            token: this.reCaptchaToken,
            type: 'Landlord',
          };
          // Send Request
          try  {
            const response: Partial<API.CreateUserResponse> = await this.$gql.Mutation<API.CreateUserResponse>('CreateUser', query, payload);
            // If errored, notify user
            let notifyPayload: NotifyAPIOptions = {
              position: 'bottom-right',
              message: '',
              timeout: 2500,
              color: 'primary',
              textColor: 'white',
              icon: 'mdi-exclamation',
            };
            // Configure notification payload
            if (response.errored) {
              notifyPayload.message = 'Ooops! Something went wrong, try again?';
              notifyPayload.color = 'red';
            } else {
              // If exists, notify user
              if (response.exists) {
                notifyPayload.message = 'You already have an account with us! Try signing in?';
                notifyPayload.timeout = 5000;
              } else {
                // If we've created the account
                if (response.created) {
                  notifyPayload.message = 'All done, we have sent an email to ' + response.email + '!';
                  notifyPayload.color = 'green';
                  notifyPayload.icon = 'mdi-thumb-up';
                  // Close sidebar
                  this.$emit('close');
                } else {
                  notifyPayload.message = 'Ooops! Something went wrong, contact support?';
                  notifyPayload.color = 'red';
                }
              }
            }
            // Notify user of outcome via Notify API
            this.$q.notify(notifyPayload);
          } catch (e) {
            console.error(e);
          } finally {
            // Remove any pop up timeouts
            window.clearTimeout(clearTimeout);
            window.clearTimeout(clearTimeout2);
          }
          // Disable loader
          this.creatingAccount = false;
        } else {
          // Notify user to agree to our terms and conditions
          this.$q.notify({
            position: 'bottom',
            message: 'Please confirm that you are not a robot',
            timeout: 4000,
            color: 'red',
            textColor: 'white',
          });
        }
      } else {
        // Notify user to agree to our terms and conditions
        this.$q.notify({
          position: 'bottom',
          message: 'Please Agree to Our Terms & Conditions',
          timeout: 4000,
          color: 'red',
          textColor: 'white',
        });
      }
    },
    setCaptchaToken (token: string) {
      this.reCaptchaToken = token;
    },
  },
});
