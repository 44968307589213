import { LandlordState } from './state';
import { RootStore } from '../index';
import { DurationMonths } from '@/assets/mixins';

export function tenancyDuration (state: LandlordState, _getters: Record<string, any>, _rootState: RootStore) {
  // Extract info
  const tenancyStart: Date = new Date(state.form.dates.tenancyStart);
  const tenancyEnd: Date = new Date(state.form.dates.tenancyEnd);
  return DurationMonths(tenancyStart, tenancyEnd);
}

export function availableProperties (state: LandlordState) {
  return state.portfolio.properties.filter((idxProp) => idxProp.property.available);
}
