




















































import Vue from 'vue';
import { email } from 'vuelidate/lib/validators';
import { ValidateFields } from '@/assets/mixins';
import { NotifyAPIOptions } from '@/interfaces/quasar';

import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';

export default Vue.extend({
  name: 'VerifyEmailRequest',
  data () {
    return {
      form: {
        email: '',
      },
      requestingEmail: false,
    };
  },
  validations: {
    form: {
      email: {
        email,
      },
    },
  },
  methods: {
    validateFields (inputs: string[]) {
      return ValidateFields(this, inputs);
    },
    async invokeVerifyEmailRequest () {
      // Check if fields are valid
      if (this.validateFields(['email']) && (this.$v.form && this.$v.form.email && !this.$v.form.email.$error)) {
        // Set loading
        this.requestingEmail = true;
        // Create query
        const query: GQLTagRequestObject = gql`
          mutation ($email: String!) {
            VerifyUserRequest (
              input: {
                email: $email
              }
            )
          }
        `;
        // Create payload
        const payload: Record<string, string> = {
          email: this.form.email,
        };
        // Send request
        try {
          const response: boolean = await this.$gql.Mutation('VerifyUserRequest', query, payload) as boolean;
          let notifyOptions: NotifyAPIOptions;
          if (response) {
            notifyOptions = {
              position: 'bottom-right',
              message: 'We got your request, we will send you an email shortly',
              timeout: 5000,
              color: 'primary',
              textColor: 'white',
            };
          } else {
            notifyOptions = {
              position: 'bottom-right',
              message: 'Failed to send your request. Contact Support for more information',
              timeout: 5000,
              color: 'red',
              textColor: 'white',
            };
          }
          this.$q.notify(notifyOptions);
          this.$emit('close');
        } catch (e) {
          console.error(e);
        } finally {
          this.requestingEmail = false;
        }
      }
    },
  },
});
