import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import './quasar';
import './registerServiceWorker';

// Determine if working locally or not
export const local: boolean = process.env.NODE_ENV === 'development';

// Determine url - Default point to live
let url: string = 'https://api-lordly.azurewebsites.net/api';
if (local) {
  url = 'http://localhost:7072/api';
}

// Boot Files -- Start
import { GQLClient } from '@/assets/clients/gqlClient';
Vue.prototype.$gql = new GQLClient(url + '/v1/gql');

import { BlobClient } from '@/assets/clients/blobClient';
Vue.prototype.$blob = new BlobClient(url + '/blob');

import { AnalyticsClient } from '@/assets/clients/analyticsClient';
Vue.prototype.$lga = new AnalyticsClient(url + '/ga');

import { VuexClient } from '@/assets/clients/vuexClient';
Vue.prototype.$vuex = new VuexClient();
// Boot Files -- End

// Vue Plugins - Google Analytics
import VueGtag from 'vue-gtag';
Vue.use(VueGtag, {
  config: { id: 'UA-143373529-1' },
  enabled: false,
}, router);

// Vue Plugins - Vuelidate
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

// Vue Plugins - Auth0 Plugin
import { auth0Plugin } from '@/assets/plugins/auth0Client3';
Vue.use(auth0Plugin, {
  domain: store.state.auth0.domain,
  clientId: store.state.auth0.clientId,
  redirectUrl: (window.location.origin + store.state.auth0.redirectPath),
  router,
  store,
  onRedirectCallback: (appState: any) => {
    // Bug: Investigate + Fix later
    // router.push((appState && appState.targetUrl) ? appState.targetUrl : window.location.pathname);
  },
});

Vue.config.productionTip = true;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
