import axios, { AxiosInstance, AxiosResponse } from 'axios';
import FormData from 'form-data';
import { Notify } from 'quasar';
import { Blob } from '@lordly/models2/interfaces';

export type BlobTypes = 'user' | 'property' | 'property-staging' | 'tenancy';

interface RequestHeaders {
  'Content-Type': 'multipart/form-data';
  'x-entity': BlobTypes;
  'x-entity-id': string;
  'x-entity-name'?: string;
}

export class BlobClient {
  // Class variable
  private url: string;
  private fileSizeLimitMB: number = 10;
  private client: AxiosInstance;

  // Constructor
  constructor (url: string) {
    this.client = axios;
    if (url) {
      this.url = url;
    } else {
      throw new Error('BlobClient failed to initialise, no url provided');
    }
  }

  // Methods
  public SetBearerToken (token: string) {
    this.client.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  }

  public ClearBearerToken () {
    delete this.client.defaults.headers.common['Authorization'];
  }

  public async Create (entity: BlobTypes, entityId: string, entityName: string | null, file: File, fileSizeLimitMB?: number): Promise<Blob[]> {
    return new Promise(async (resolve, reject) => {
      const fileSize: number = (file.size / 1024 / 1024); // In MB
      let errorMessage: string = '';
      // If an override file size limit is provided, use that else use the default value
      const limit: number = fileSizeLimitMB || this.fileSizeLimitMB;
      if (fileSize <= limit) {
        // Create new formdata and attach file
        const form: FormData = new FormData();
        form.append('file', file);
        // Setup headers
        const headers: RequestHeaders = {
          'Content-Type': 'multipart/form-data',
          'x-entity': entity,
          'x-entity-id': entityId,
        };
        // If entity name is provided, set it
        if (entityName != null) {
          headers['x-entity-name'] = entityName;
        }
        // Send request
        try {
          const response: AxiosResponse = await this.SendRequest('create', form, headers);
          // Determine if response is valid
          if (response.status === 202) {
            resolve(response.data.blobs);
          } else {
            errorMessage = 'API returned unexpected status code: ' + response.status;
            this.HandleError(file.name, errorMessage);
            reject(errorMessage);
          }
        } catch (e) {
          errorMessage = 'Failed to upload file';
          this.HandleError(file.name, errorMessage);
          reject(errorMessage);
        }
      } else {
        errorMessage = 'File is too large';
        this.HandleError(file.name, errorMessage);
        reject(errorMessage);
      }
    });
  }

  public async Get (entity: BlobTypes, entityPostFix: string): Promise<Blob[]> {
    return new Promise(async (resolve, reject) => {
      let errorMessage: string = '';
      // Generate payload
      const payload: { entity: BlobTypes, stagingID: string } = {
        entity,
        stagingID: entityPostFix,
      };
      // Send Request
      try {
        const response: AxiosResponse = await this.SendRequest('get', payload);
        if (response.status === 200) {
          resolve(response.data);
        } else {
          errorMessage = 'API returned unexpected status code: ' + response.status;
          this.HandleError(entityPostFix, errorMessage);
          reject(errorMessage);
        }
      } catch (e) {
        errorMessage = 'Failed to get blob';
        this.HandleError(entityPostFix, errorMessage);
        reject(errorMessage);
      }
    });
  }

  public async Delete (entity: BlobTypes, entityPostFix: string, blobId: string, blobUrl: string) {
    return new Promise(async (resolve, reject) => {
      let errorMessage: string = '';
      // Generate layload
      const payload: { entity: BlobTypes, entityID: string, blobID: string, blobURL: string } = {
        entity,
        entityID: entityPostFix,
        blobID: blobId,
        blobURL: blobUrl,
      };
      // Send Request
      try {
        const response: AxiosResponse = await this.SendRequest('delete', payload);
        if (response.status === 204) {
          resolve();
        } else {
          errorMessage = 'API returned unexpected status code: ' + response.status;
          this.HandleError(entityPostFix, errorMessage);
          reject(errorMessage);
        }
      } catch (e) {
        errorMessage = 'Failed to delete blob';
        this.HandleError(entityPostFix, errorMessage);
        reject(errorMessage);
      }
    });
  }

  // Warm blob apis endpoints
  public async Warm () {
    const urls: string [] = [];
    // Create warm urls
    urls.push((this.url + '/' + 'get/true')); // GET
    urls.push((this.url + '/' + 'create/true')); // CREATE
    urls.push((this.url + '/' + 'delete/true')); // DELETE

    // Send request to warm apis
    for (const idx in urls) {
      if (urls[idx]) {
        axios.post(urls[idx]);
      }
    }
  }

  private async SendRequest (urlAppend: string, data: any, headers?: RequestHeaders) {
      return this.client({
        method: 'POST',
        url: this.url + '/' + urlAppend,
        headers,
        data,
        timeout: 60000,
      });
  }

  private HandleError (fileName: string, message: string) {
    Notify.create({
      message: `
        <p style="margin:0;margin-top:1rem"><b>File: </b>${fileName}</p>
        <p style="margin:0;margin-bottom:1rem"><b>Message: </b>${message}</p>
      `,
      html: true,
      color: 'red',
      position: 'top-right',
      timeout: 5000,
      textColor: 'white',
    });
  }
}
