
































import Vue from 'vue';
import { UpdateApp } from '@/assets/mixins';

export default Vue.extend({
  name: 'Help',
  methods: {
    openDefaultMailClient () {
      // Variables
      const email: string = 'jackson.jacob@lordly.app';
      // Email
      const emailSubject: string = 'Requesting help from Lordly.app - ' + new Date();
      const emailBody: string = 'Hi,%0D%0A%0D%0A [INSERT QUERY HERE] %0D%0A%0D%0AKind Regards%0D%0A';
      window.location.href = 'mailto:' + email + '?subject=' + emailSubject + '&body=' + emailBody;
    },
    updateApplication () {
      UpdateApp();
    },
  },
});
