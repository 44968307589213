import { SearchState } from './state';
import { HandleVuexError, MutateVuexStore } from '../../assets/mixins';
import { MutationPayload } from '@/interfaces';
import { Property } from '@lordly/models2/interfaces/models/Property';

const errorHandler: HandleVuexError = new HandleVuexError('Search');

export function MutateStore (state: SearchState, payload: MutationPayload) {
  MutateVuexStore(errorHandler, state, payload);
}

// FAKE LOADING
export function FakeLoading (state: SearchState, payload: boolean) {
  if (typeof payload === 'boolean') {
    state.inquiry.loading = payload;
  } else {
    errorHandler.ReportError(new Error('FakeLoading - Payload is not of type boolean'));
  }
}

// REORDER
export function ReorderResultsBedroom (state: SearchState, orderByMin: boolean) {
  let oldList: Array<Partial<Property>> = state.inquiry.results;
  oldList.sort((a, b) => {
    if (a.details!.bedroom > b.details!.bedroom) {
      return orderByMin ? 1 : -1;
    } else {
      return orderByMin ? -1 : 1;
    }
  });
}

export function ReorderResultsRent (state: SearchState, orderByMin: boolean) {
  let oldList: Array<Partial<Property>> = state.inquiry.results;
  oldList.sort((a, b) => {
    if (parseFloat(a.rent!.computed.weekly) > parseFloat(b.rent!.computed.weekly)) {
      return orderByMin ? 1 : -1;
    } else {
      return orderByMin ? -1 : 1;
    }
  });
}

export function ReorderResultsDeposit (state: SearchState, orderByMin: boolean) {
  let oldList: Array<Partial<Property>> = state.inquiry.results;
  oldList.sort((a, b) => {
    if (parseFloat(a.deposit!.computed.perperson) > parseFloat(b.deposit!.computed.perperson)) {
      return orderByMin ? 1 : -1;
    } else {
      return orderByMin ? -1 : 1;
    }
  });
}

// LOCAL STORAGE MUTATIONS
export function GenerateDictionary (state: SearchState, payload: 'result' | 'saved') {
  if (payload && (payload === 'result' || payload === 'saved')) {
    // Setup list and new dictionary
    let propertyList: Array<Partial<Property>>;
    let propertyDictionary: Record<string, number> = {};
    // Determine how to initialise variables
    if (payload === 'result') {
      propertyList = state.inquiry.results;
    } else {
      propertyList = state.inquiry.resultsSaved;
    }
    // Create dictionary
    for (const idx in propertyList) {
      if (propertyList[idx]) {
        const property: Partial<Property> = propertyList[idx];
        propertyDictionary[property.id!] = parseInt(idx);
      }
    }
    // Save dictionary
    if (payload === 'result') {
      state.inquiry.resultsDictionary = propertyDictionary;
    } else {
      state.inquiry.resultsSavedDictionary = propertyDictionary;
    }
  } else {
    errorHandler.ReportError(new Error('GenerateDictionary - Payload invalid, should be result or saved'));
  }
}

export function LoadFromLocalStorage (state: SearchState) {
  if (window.localStorage) {
    // Load properties from local storage
    const locallySavedStringifiedResults: string | null = window.localStorage.getItem('search_saved');
    if (locallySavedStringifiedResults) {
      const locallySavedResults: Array<Partial<Property>> = JSON.parse(locallySavedStringifiedResults);
      // Determine if property in saved list
      for (let idx in locallySavedResults) {
        if (locallySavedResults[idx]) {
          let found: boolean = false;
          const localResult: Partial<Property> = locallySavedResults[idx];
          // Determine if in locally saved list
          if (state.inquiry.resultsDictionary[localResult.id!] !== undefined) {
            found = true;
          }
          // Add to saved list
          if (!found) {
            state.inquiry.resultsSaved.push(localResult);
          }
        }
      }
      // Generate Dictionary
      GenerateDictionary(state, 'saved');
    }
  }
}

export function SaveResult (state: SearchState, payload: string) {
  if (payload && (typeof payload === 'string')) {
    // Extract property
    const property: Partial<Property> = state.inquiry.results[state.inquiry.resultsDictionary[payload]];
    // Save to local storage
    if (window.localStorage) {
      // Get current saved list
      const currentSavedList: Array<Partial<Property>> = GetSavedList();
      // If there are less than 30 properties saved, save to list
      if (currentSavedList.length < 30) {
        // Check if already saved
        if (state.inquiry.resultsSavedDictionary[property.id!] === undefined) {
          // Save
          currentSavedList.push(property);
          SaveToLocalStorage(JSON.stringify(currentSavedList));
          // Update Vuex saved list
          state.inquiry.resultsSaved.push(property);
          GenerateDictionary(state, 'saved');
        }
      }
    }
  } else {
    errorHandler.ReportError(new Error('SaveToLocalStorage - Payload is not valid'));
  }
}

export function UnsaveResult (state: SearchState, payload: string) {
  if (payload && (typeof payload === 'string')) {
    // Retrieve from local storage
    if (window.localStorage) {
      // Get current saved list
      const currentSavedList: Array<Partial<Property>> = GetSavedList();
      for (const idx in currentSavedList) {
        if (currentSavedList[idx]) {
          const property: Partial<Property> = currentSavedList[idx];
          if (property.id! === payload) {
            currentSavedList.splice(parseInt(idx), 1);
            // Update Vuex saved list
            state.inquiry.resultsSaved.splice(state.inquiry.resultsSavedDictionary[payload], 1);
          }
        }
      }
      SaveToLocalStorage(JSON.stringify(currentSavedList));
      GenerateDictionary(state, 'saved');
    }
  } else {
    errorHandler.ReportError(new Error('RemoveFromLocalStorage - Payload is not valid'));
  }
}

// Helper functions
function SaveToLocalStorage (value: string) {
  if (window.localStorage) {
    window.localStorage.setItem('search_saved', value);
  }
}

function GetSavedList (): Array<Partial<Property>> {
  // Initialise response
  let response: Array<Partial<Property>> = [];
  // Get value from local straoge
  if (window.localStorage) {
    // Get current saved list
    const currentSavedListStringified: string | null = window.localStorage.getItem('search_saved');
    // We have a list
    if (currentSavedListStringified) {
      response = JSON.parse(currentSavedListStringified);
    }
  }
  return response;
}
