import Vue from 'vue';
import Vuex, { Store } from 'vuex';

// Import Vuex Modules
import search from './search';
import { SearchState } from './search/state';
import landlord from './landlord';
import { LandlordState } from './landlord/state';

Vue.use(Vuex);

const store: Store<RootStore> = new Vuex.Store({
  state: {
    possibleCities: [''],
    auth0: {
      domain: 'lordly.eu.auth0.com',
      clientId: 'IH2NUQsm6gySE4WWfduiBlFKy978UbhI',
      redirectPath: '/authorize',
    },
    dateMask: 'YYYY/MM/DD',
    dateMaskInput: '####/##/##',
    avatar: 'https://www.lordly.app/img/app/icons/profile-image.png',
  },
  mutations: {
    mPossibleCities (state, payload: string[]) {
      state.possibleCities = payload;
    },
  },
  actions: {},
  modules: {
    search,
    landlord,
  },
  // enable strict mode (adds overhead!)
  // for dev mode only
  strict: process.env.NODE_ENV === 'development',
});

// HMR for Vuex
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept(['./search', './landlord'], () => {
    const newSearch: any = require('./search').default;
    const newLandlord: any = require('./landlord').default;
    store.hotUpdate({ modules: { search: newSearch, landlord: newLandlord} });
  });
}

export default store;

export interface RootStore {
  possibleCities: string[];
  auth0: {
    domain: string;
    clientId: string;
    redirectPath: string;
  };
  dateMask: string;
  dateMaskInput: string;
  avatar: string;
  search?: SearchState;
  landlord?: LandlordState;
}
