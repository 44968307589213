import { AnalyticTypes, AnalyticsRequest } from '@lordly/models2/interfaces/models/Analytics';
import axios from 'axios';

export class AnalyticsClient {
  // Class variables
  private url: string;
  // Constructor
  constructor (url: string) {
    this.url = url;
  }
  // Method
  public TrackEvolution (propertyId: string, city: string) {
    this.SendAnalytics('Evolution', propertyId, city);
  }

  public TrackMoreInfo (propertyId: string, city: string) {
    this.SendAnalytics('MoreInfo', propertyId, city);
  }

  private async SendAnalytics (type: AnalyticTypes, propertyId: string, city: string) {
    // Create layload
    const payload: AnalyticsRequest = {
      t: type,
      s: this.GetSubscription(propertyId),
      p: propertyId,
      c: city,
    };
    // Send Request
    try {
      await axios({
        method: 'POST',
        url: this.url,
        data: payload,
        timeout: 3000,
      });
    } catch (e) {
      console.error(e);
    }
  }

  private GetSubscription (propertyId: string) {
    const sections: string[] = propertyId.split('-');
    if (sections.length === 2) {
      return sections[0];
    } else {
      throw new Error('Parameter proeprtyId is invalid format');
    }
  }

}
