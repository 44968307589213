var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-menu signup",attrs:{"data-cy":"signup-component"}},[_c('h3',[_vm._v("Sign Up")]),_c('img',{staticClass:"signup-ills",attrs:{"src":_vm.signupIllustration,"alt":"Sign Up"}}),_c('div',{staticClass:"info"},[_c('form',{staticClass:"center h",attrs:{"autocomplete":"aoto3mplt3eNO"},on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showPhase2),expression:"!showPhase2"}],staticClass:"phase1"},[_c('div',{staticClass:"input-container"},[_c('label',[_vm._v("Enter your firstname")]),_c('q-input',{ref:"firstname",attrs:{"data-cy":"signup-form-firstname","outlined":"","hint":"E.g. Albert","rules":[
              function (val) { return !!val || '* Required'; },
              function (val) { return val.length < 100 || 'Please use less than 100 characters are maximum'; }
            ],"lazy-rules":"","debounce":"100"},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})],1),_c('div',{staticClass:"input-container"},[_c('label',[_vm._v("Enter your surname")]),_c('q-input',{ref:"surname",attrs:{"data-cy":"signup-form-surname","outlined":"","hint":"E.g. Einstein","rules":[
              function (val) { return !!val || '* Required'; },
              function (val) { return val.length < 100 || 'Please use less than 100 characters are maximum'; }
            ],"lazy-rules":"","debounce":"100"},model:{value:(_vm.form.surname),callback:function ($$v) {_vm.$set(_vm.form, "surname", $$v)},expression:"form.surname"}})],1),_c('div',{staticClass:"input-container",staticStyle:{"margin-bottom":"1rem"}},[_c('label',[_vm._v("Enter your email")]),_c('q-input',{ref:"email",attrs:{"data-cy":"signup-form-email","error":_vm.$v.form.email.$error,"outlined":"","hint":"E.g. Albert.Einstein@science.com","rules":[
              function (val) { return !!val || '* Required'; },
              function (val) { return val.length < 100 || 'Please use less than 100 characters are maximum'; }
            ],"lazy-rules":"","clearble":"","debounce":"100"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submitButtonAction()},"input":function($event){return _vm.$v.form.email.$touch()}},scopedSlots:_vm._u([{key:"error",fn:function(){return [_vm._v(" "+_vm._s(_vm.$v.form.email.$error ? 'Please enter a valid email address' : '')+" ")]},proxy:true}]),model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)]),(_vm.showPhase2)?_c('div',{staticClass:"phase2"},[_c('div',{staticClass:"input-container"},[_c('label',[_vm._v("Enter your password")]),_c('q-input',{ref:"password",attrs:{"autofocus":"","data-cy":"signup-form-password","error":_vm.$v.form.password.$error,"outlined":"","placeholder":"","type":"password","rules":[
              function (val) { return !!val || '* Required'; },
              function (val) { return val.length < 100 || 'Please use less than 100 characters are maximum'; }
            ],"lazy-rules":"","clearable":"","debounce":"250"},on:{"input":function($event){return _vm.$v.form.password.$touch()}},scopedSlots:_vm._u([{key:"error",fn:function(){return [_vm._v(" "+_vm._s(_vm.$v.form.password.$error ? 'Must be at least 8 characters and contain a lowercase character, uppercase character and a number' : '')+" ")]},proxy:true}],null,false,2183198666),model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('div',{staticClass:"input-container"},[_c('label',[_vm._v("Re-enter your password")]),_c('q-input',{ref:"passwordConfirmation",attrs:{"data-cy":"signup-form-passwordConfirmation","error":_vm.$v.form.passwordConfirmation.$error,"outlined":"","placeholder":"","type":"password","rules":[
              function (val) { return !!val || '* Required'; },
              function (val) { return val.length < 100 || 'Please use less than 100 characters are maximum'; }
            ],"lazy-rules":"","clearable":"","debounce":"250"},on:{"input":function($event){return _vm.$v.form.passwordConfirmation.$touch()}},scopedSlots:_vm._u([{key:"error",fn:function(){return [_vm._v(" "+_vm._s(_vm.form.passwordConfirmation !== _vm.form.password ? 'Passwords do not match' : '')+" ")]},proxy:true}],null,false,259411650),model:{value:(_vm.form.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.form, "passwordConfirmation", $$v)},expression:"form.passwordConfirmation"}})],1),_c('captcha',{on:{"token":_vm.setCaptchaToken}}),_c('div',{staticClass:"input-container"},[_c('q-item',{attrs:{"tag":"label","data-cy":"signup-form-tc"}},[_c('q-item-section',{attrs:{"avatar":""}},[_c('q-checkbox',{attrs:{"value":"true","color":"primary"},model:{value:(_vm.agreedToTermsAndConditions),callback:function ($$v) {_vm.agreedToTermsAndConditions=$$v},expression:"agreedToTermsAndConditions"}})],1),_c('q-item-section',[_c('q-item-label',[_c('p',{attrs:{"title":"View Terms and Conditions"}},[_vm._v("By Signing Up You Agree to Our "),_c('router-link',{attrs:{"to":"/legal/toc"},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("T & C's")])],1)])],1)],1)],1)],1):_vm._e(),_c('div',{staticClass:"persistent"},[_c('div',{staticClass:"input-container"},[_c('q-btn',{staticClass:"full-width",attrs:{"data-cy":"signup-form-submit","color":"secondary","label":_vm.submitButtonLabel,"no-caps":"","type":"button","unelevated":"","disable":_vm.creatingAccount,"loading":_vm.creatingAccount,"ripple":false},on:{"click":function($event){return _vm.submitButtonAction()}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPhase2),expression:"showPhase2"}],staticClass:"input-container"},[_c('q-btn',{staticClass:"full-width",attrs:{"color":"primary","label":"Back","no-caps":"","type":"button","ripple":false,"disable":_vm.creatingAccount},on:{"click":function($event){return _vm.invokePhase1()}}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }