import Vue from 'vue';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import VueRouter, { Route } from 'vue-router';

import { Store } from 'vuex';
import { RootStore } from '@/store';
import { MutationPayload  } from '@/interfaces';

// Interfaces
import { CombinedVueInstance } from 'vue/types/vue';
import { GQLClient } from '@/assets/clients/gqlClient';
import { BlobClient } from '@/assets/clients/blobClient';

// Globally available instance
export let auth0StaticInstance: Auth0Instance;

/** Get the current auth0 static instance of the SDK */
export function GetInstance (): Auth0Instance {
  return auth0StaticInstance;
}

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK: () => void = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

/** Navigation auth guard to prevent users access to protected endpoints */
export const auth0NavGuard: (to: Route, from: Route, next: (path?: string) => void) => void = (to, _from, next) => {
  // Get the auth0 plugin instance
  const authService: Auth0Instance = GetInstance();

  const fn: () => void = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      return next();
    }
    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0Plugin: (x: UseAuth0PluginOptions | Auth0PluginOptions) => Auth0Instance = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...options
}) => {

  // If static instance exists, return it
  if (auth0StaticInstance) {
    return auth0StaticInstance;
  }

  // Static instance does not exist, create new instance of plugin
  auth0StaticInstance = new Vue({
    data () {
      return {
        loading: true,
        isAuthenticated: false,
        user: {
          id: '',
          subscription: '',
          email: '',
          type: '',
        } as LordlyUser,
        auth0Client: null as unknown as Auth0Client,
        popupOpen: false,
        error: null,
        appRouter: null as unknown as VueRouter,
        appStore: null as unknown as Store<RootStore>,
        jwtToken: '',
      } as PluginData;
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created () {
      // Initailize vue router; when a plugin is created, it loses reference to app router so you have to pass it in as a variable and keep it in memory for reuse
      this.appRouter = options.router;
      // Initialize vuex store; same problem as router
      this.appStore = options.store;

      // Create a new instance of the SDK client using members of the given options object
      this.auth0Client = await createAuth0Client({
        domain: options.domain,
        client_id: options.clientId,
        redirect_uri: options.redirectUrl || window.location.origin,
      });

      // Flag to signal if auth has been checked or not; prevent checking twice
      let isAuthChecked: boolean = false;

      try {
        // If the user is returning to the app after authentication...
        if (
          window.location.search.includes('code=') &&
          window.location.search.includes('state')
        ) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback();

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState);

          // If auth not checked, check if authorized
          if (!isAuthChecked) {
            this.isAuthenticated = await this.auth0Client.isAuthenticated();
            isAuthChecked = true;

            // Redirect if authenticated
            if (this.isAuthenticated) {
              // Update data
              await this.updateData();
              // Redirect user to relevant layout
              this.routeUser();
            }
          }
        }
      } catch (e) {
        this.error = e;
      } finally {
        // Initialize our internal authentication state (done when user has not rerouted from authentication but still signed in)
        if (!isAuthChecked) {
          this.isAuthenticated = await this.auth0Client.isAuthenticated();
          isAuthChecked = true;
          await this.updateData();
          // DevNote: Do not reroute user here because it is annoying AF
        }
        this.loading = false;
      }
      // Bug: Need global loading page in mobile viewport when signing in
      // If mobile viewport and isAuthenticated, redirect to landlord dashboard instead of landing page automatically
      if (window.innerWidth < 599) {
        if (this.isAuthenticated) {
          this.routeUser();
        }
      }
    },
    methods: {
      /** Authenticates the user using the redirect method */
      loginWithRedirect (o) {
        return this.auth0Client.loginWithRedirect(o);
      },
      /** Authenticates the user using a popup window */
      async loginWithPopup (o: any) {
        this.popupOpen = true;

        try {
          await this.auth0Client.loginWithPopup(o);
        } catch (e) {
          // eslint-disable-next-line
          console.error(e);
        } finally {
          this.popupOpen = false;
        }

        this.user = await this.auth0Client.getUser();
        this.isAuthenticated = true;
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback () {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = true;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
        // Return empty object as technically we should return RedirectLoginResult but we do not as the example does not
        return {} as any;
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims (o) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently (o) {
        return this.auth0Client.getTokenSilently(o);
      },
      /** Gets the access token using a popup window */
      getTokenWithPopup (o) {
        return this.auth0Client.getTokenWithPopup(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      logout (instance: CombinedVueInstance<any, any, any, any, any>) {
        // Clear tokens
        if (instance && instance.$blob) {
          (instance.$blob as BlobClient).ClearBearerToken();
        }
        // Done last because the instance passed in this refers to the instace being cleared in the $gql state if a request is 401
        if (instance && instance.$gql) {
          (instance.$gql as GQLClient).ClearBearerToken();
        }
        // Tell Auth0 to return to the home page
        return this.auth0Client.logout({
          returnTo: window.location.origin,
        });
      },
      /** Update the Vuex store */
      updateAppVuexStore (key: string, value: string) {
        const payload: MutationPayload = {
          key,
          value,
        };
        this.appStore.commit('landlord/MutateStore', payload);
      },
      /** Update plugin and vuex data */
      async updateData () {
        // Extract JWT
        const data: Auth0IdTokenClaims = await this.auth0Client.getIdTokenClaims() as any;

        // If we get any data
        if (data) {
          // Store JWT
          this.jwtToken = data.__raw;

          // Update user information on plugin and Vuex
          const userId: string = data['https://www.lordly.app/id'];
          this.updateAppVuexStore('user.id', userId);
          this.user.id = userId;

          const userSub: string = data['https://www.lordly.app/subscription'];
          this.updateAppVuexStore('user.subscription', userSub);
          this.user.subscription = userSub;

          const userEmail: string = data['email'];
          this.updateAppVuexStore('user.email', userEmail);
          this.user.email = userEmail;

          const userType: string = data['https://www.lordly.app/type'];
          this.user.type = userType;
        }
      },
      /** Route user to the relevant layout depending on the type */
      routeUser () {
        if (this.user.type === 'Landlord') {
          this.appRouter.push('/landlord');
        } else {
          console.error('Unknown user enriched type provided', this.user.type);
          this.logout(null);
        }
      },
      notifyError (message: string) {
        this.$q.notify({
          message,
          color: 'red',
          position: 'bottom',
          timeout: 2500,
          textColor: 'white',
        });
        // Logout after 3 secs
        setTimeout(() => {
          this.logout(this);
        }, 4000);
      },
    },
  });

  // Once created return instance
  return auth0StaticInstance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const auth0Plugin: any = {
  // tslint:disable-next-line
  install (Vue: any, options: Auth0PluginOptions) {
    Vue.prototype.$auth0 = useAuth0Plugin(options);
  },
};

// Plugin interfaces
export type Auth0Instance = CombinedVueInstance<Vue, PluginData, PluginMethods, PluginComputed, PluginProps>;

interface PluginData {
  loading: boolean;
  isAuthenticated: boolean;
  user: LordlyUser;
  auth0Client: Auth0Client;
  popupOpen: boolean;
  error: string | null;
  appRouter: VueRouter;
  appStore: Store<RootStore>;
  jwtToken: string;
}

interface PluginMethods {
  loginWithRedirect: Auth0Client['loginWithRedirect'];
  loginWithPopup: Auth0Client['loginWithPopup'];
  handleRedirectCallback: Auth0Client['handleRedirectCallback'];
  getIdTokenClaims: Auth0Client['getIdTokenClaims'];
  getTokenSilently: Auth0Client['getTokenSilently'];
  getTokenWithPopup: Auth0Client['getTokenWithPopup'];
  logout: (instance: CombinedVueInstance<any, any, any, any, any>) => void;
  updateAppVuexStore: (key: string, value: string) => void;
  updateData: () => void;
  routeUser: (type: string) => void;
  notifyError: (message: string) => void;
}

interface PluginComputed {
  [x: string]: any;
}

interface PluginProps {
  [x: string]: any;
}

interface Auth0PluginOptions {
  domain: string;
  clientId: string;
  redirectUrl: string;
  router: VueRouter;
  store: Store<RootStore>;
  onRedirectCallback: (appState: any) => void;
}

type UseAuth0PluginOptions  = {
  useAuth0Plugin: (x: any) => void;
} & Auth0PluginOptions;

interface Auth0IdTokenClaims {
  __raw: string;
  'https://www.lordly.app/id': string;
  'https://www.lordly.app/subscription': string;
  'https://www.lordly.app/type': 'Landlord';
  email: string;
  [x: string]: any;
}

interface LordlyUser {
  id: string;
  subscription: string;
  email: string;
  type: string;
}
